.enter_pin .title {
  margin-bottom: 45px;
  font-size: 1.4375em;
  font-weight: 400;
  text-align: center;
}

.enter_pin_container {
  margin-bottom: 30px;

  h5 {
    margin-bottom: 24px;
    font-size: 1em;
    font-weight: 400;
    text-align: center;
  }

  .pin_container {
    @include flex-position(center, center);

    input {
      height: 55px;
      width: 55px !important;
      text-align: center;
      font-size: 1.625em;
      font-weight: 500;
      border: none;
      outline: none;
      border-bottom: 1px solid $color-input-border;

      &:focus {
        border: none !important;
        outline: none !important;
        border-bottom: 1px solid $color-orange !important;
      }
    }
  }
}
