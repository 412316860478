.section_header_container {
  margin-bottom: 25px;

  .head_text_div {
    @include flex-position(space-between, center);

    h6 {
      font-weight: 600;
      font-size: 1.2em;

      @media screen and (max-width: 380px) {
        font-size: 1.1em;
      }
    }

    .close {
      cursor: pointer;
    }

    svg {
      transform: rotate(270deg);

      @media screen and (max-width: 380px) {
        width: 21px;
      }

      path {
        fill: $color-black;
      }
    }
  }

  p {
    margin-top: 4px;
    color: $color-grey;
    font-size: 0.95em;
    line-height: inherit;

    a {
      color: $color-success;
      font-weight: 500;
      text-decoration: underline;
    }
  }
}
