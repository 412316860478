.sign_in_page {
  @include flex-position(flex-start, flex-start);
  width: 100%;
  min-height: 100vh;
  background: $color-orange;

  &.register_page {
    @media screen and (max-height: 1105px) {
      .form_container {
        position: relative;
        top: auto;
        left: auto;
        transform: none;
      }
    }
  }

  &.onboarding_page {
    flex-direction: column;

    .skip_more_info {
      margin: 0 auto;
      margin-top: -19px;
      margin-bottom: 20px;
      font-weight: 400;
      font-size: 0.9em;
      text-decoration: underline;
      text-align: center;
      width: fit-content;
      cursor: pointer;
    }

    .logout {
      margin: 0 auto 30px auto;
      font-size: 0.95em;
      text-decoration: underline;
      color: $color-white;
      width: fit-content;
      cursor: pointer;
    }
  }

  .form_container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 50px 12px;
    width: 100%;

    @media screen and (max-height: 810px) {
      position: relative;
      top: auto;
      left: auto;
      transform: none;
    }

    @media screen and (max-width: 450px) {
      padding: 24px 12px;
    }

    .logo_container {
      margin-bottom: 35px;

      img {
        width: 85px;
        height: 85px;
        border-radius: 50%;
      }
    }

    .form {
      margin: 0 auto;
      padding: 61px 12px;
      padding-bottom: 73px;
      max-width: 480px;
      background: $color-white;
      border-radius: 10px;
      box-shadow: $box-shadow;

      .form_inner {
        margin: 0 auto;
        max-width: 366px;
      }

      h5 {
        margin-bottom: 30px;
        color: $color-black-2;
        text-align: center;
        font-weight: 500;
        font-size: 1.8125em;
        letter-spacing: -0.03em;
      }

      h6 {
        margin-top: -21px;
        margin-bottom: 30px;
        color: $color-grey-3;
        text-align: center;
        font-weight: 400;
        font-size: 1em;
        letter-spacing: -0.03em;
      }

      .form-section-title {
        margin-top: 32px;
        margin-bottom: 12px;
        font-weight: 400;
        font-size: 16px;
        letter-spacing: -0.03em;
        color: $color-black-2;
      }

      .bnv_info {
        @include flex-position(flex-start, flex-start);

        svg {
          margin-right: 16px;
        }

        p {
          font-weight: 400;
          font-size: 0.875em;
          line-height: 17px;
          color: $color-black-2;
        }
      }

      .forgot_password {
        margin-bottom: 35px;
        font-size: 0.8125em;
        text-align: center;

        a {
          color: $color-blue;
          font-weight: 400;
        }
      }

      .datepicker_container {
        width: 100%;

        .react-datepicker-wrapper {
          width: 100%;
        }
      }

      .onboarding_complete {
        @include flex-position(center, center);
        flex-direction: column;

        svg {
          margin-bottom: 30px;
          width: 90px;
          height: 90px;
        }

        .infos {
          margin-top: 10px;

          .item {
            margin-bottom: 20px;
            text-align: center;

            h6 {
              margin: 0;
              margin-bottom: 8px;
              font-weight: 500;
              font-size: 1.125em;
              letter-spacing: -0.03em;
              color: $color-black-2;
            }

            p {
              margin: 0;
              font-weight: 400;
              font-size: 1em;
              letter-spacing: -0.03em;
              color: $color-grey-3;
            }
          }
        }

        .actions {
          width: 100%;
        }
      }

      .actions {
        margin-top: 40px;

        .btn_primary {
          padding: 15px 10px;
          font-size: 0.875em;
          width: 100%;
        }

        .or {
          margin: 15px 0px;
          font-weight: 500;
          font-size: 0.875em;
          text-align: center;
          color: $color-black;
        }

        .btn_secondary {
          padding: 15px 10px;
          font-size: 0.875em;
          width: 100%;
        }

        .didnt_get_otp {
          margin-top: 30px;
          margin-bottom: 30px;
          font-weight: 500;
          font-size: 0.875em;
          text-align: center;

          p {
            color: #161616;
          }

          .btn_text {
            margin: 0 auto;
            margin-top: 15px;
            color: $color-blue;
            cursor: pointer;
            width: fit-content;
          }
        }

        .btn_cancel {
          margin: 0 auto;
          font-weight: 500;
          font-size: 0.875em;
          color: #a2a0a8;
          width: fit-content;
          cursor: pointer;
        }
      }
    }
  }
}
