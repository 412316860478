.modal {
  background-color: none;

  &::-webkit-scrollbar {
    width: 0px;
  }

  .modal-dialog {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) !important;
    margin: auto;
    padding: 20px 0px;
    width: 600px;
    max-width: 600px;

    @media screen and (max-width: 600px) {
      padding: 20px 12px;
      width: 100%;
    }

    &.size_medium {
      width: 480px;
      max-width: 480px;

      @media screen and (max-width: 480px) {
        width: 100%;
      }
    }

    .modal-content {
      width: 100%;
      background: $color-white;
      box-shadow: $box-shadow-form;

      .section_header_container {
        margin: 0px;
        padding: 14px 24px;
        border-bottom: 1px solid $color-border-1;

        @media screen and (max-width: 380px) {
          padding: 14px 12px;
        }

        h6 {
          font-weight: 500;

          &.colored {
            color: $color-orange;
          }
        }
      }

      .form {
        overflow-y: auto;
      }

      .body {
        margin-top: 25px;
        padding: 0px 24px;

        @media screen and (max-width: 380px) {
          padding: 0px 12px;
        }
      }

      .action {
        @include flex-position(space-between, center);
        margin-top: 40px;
        padding: 9px 24px;
        border-top: 1px solid $color-border-1;

        @media screen and (max-width: 380px) {
          padding: 9px 12px;
        }

        .btn_secondary {
          padding: 13px 25px;
          font-weight: 500;
          width: 100%;
        }

        .btn_primary {
          margin: 0 0 0 auto;
          padding: 13px 25px;
          font-weight: 500;
          width: 100%;

          .loader_img {
            width: 25px;
          }
        }
      }
    }
  }

  .confirmation_modal .body {
    font-weight: 400;
    font-size: 0.9375em;
    line-height: inherit;
    color: $color-black-2;
  }

  .done_modal .modal-content {
    background: transparent;

    .body {
      @include flex-position(center, center);
      flex-direction: column;
      margin: 0 !important;
      padding: 68px 24px 38px 24px;
      background: $color-orange;

      svg {
        margin-bottom: 18px;
        width: 90px;
        height: 90px;
      }

      p {
        margin-bottom: 60px;
        font-size: 1.6em;
        color: $color-white;
        font-weight: 600;
        letter-spacing: -0.03em;
      }

      .btn_secondary {
        width: 100%;
      }
    }
  }

  .send_money_modal .body {
    .methods {
      margin-bottom: 30px;
      max-height: 350px;
      height: 100%;
      overflow-y: auto;

      .method {
        @include flex-position(space-between, center);
        margin-bottom: 20px;
        padding: 12px 14px;
        border: 1px solid $color-textfield-bg;
        border-radius: 4px;
        cursor: pointer;

        &:last-child {
          margin: 0;
        }

        .left {
          p {
            text-transform: capitalize;
          }

          span {
            display: block;
            font-size: 0.85em;
            color: $color-grey-2;
          }
        }

        .right svg {
          width: 20px;
          transform: rotate(-90deg);
        }
      }
    }

    &.no_padding {
      padding: 0;
    }

    .form {
      .form_inner {
        padding: 0px 24px;
      }

      .account_name {
        margin-top: -20px;
        margin-bottom: 24px;
        font-size: 0.95em;
        font-weight: 500;

        &.validating {
          font-weight: 400;
          font-style: italic;
        }
      }
    }

    .select_payment {
      padding: 0px 24px;
      padding-bottom: 40px;

      .transaction_summary {
        margin-bottom: 35px;
        padding: 12px 14px;
        text-align: center;
        background: #e9fff4;
        border-radius: 4px;

        h5 {
          margin-bottom: 4px;
          font-size: 1em;
          font-weight: 500;
        }

        p {
          margin-bottom: 4px;
          color: #a2a0a8;
        }

        h6 {
          font-size: 0.95em;
          color: $color-error;
        }
      }

      .enter_pin_container {
        margin: 0 auto;
        max-width: 370px;
        margin-bottom: 32px;

        h5 {
          margin-bottom: 12px;
          font-size: 0.9em;
        }
      }
    }
  }

  .card_payment_modal .body {
    max-width: 330px;
    margin-left: auto;
    margin-right: auto;

    .d_flex {
      @include flex-position(space-between, center);
      margin-bottom: 24px;

      .joint_fields {
        margin-right: 20px;

        .expiration_label {
          margin-bottom: 5px;
          font-weight: 400;
          font-size: 0.8125em;
          color: $color-grey;
        }

        .expiration_fields {
          @include flex-position(flex-start, center);
          border: 1px solid $color-border;
          border-radius: 10px;

          &:focus-within {
            border: 1px solid $color-orange;
          }

          .form_group_container {
            margin: 0;

            .form-control {
              border: none;

              &:focus {
                border: none !important;
              }
            }
          }

          span {
            font-size: 1.5em;
            color: $color-grey-2;
          }
        }
      }
    }
  }

  .create_account_modal .body {
    .methods {
      padding-bottom: 50px;

      .method {
        display: block;
        margin-bottom: 20px;
        padding: 12px 14px;
        border: 1px solid $color-textfield-bg;
        border-radius: 4px;
        cursor: pointer;

        &:last-child {
          margin: 0;
        }

        .d_flex {
          @include flex-position(flex-start, center);

          svg {
            margin-right: 10px;
            width: 30px;
            height: 30px;
          }

          p {
            font-weight: 500;
          }
        }

        .highlights {
          margin-top: 8px;
          padding-left: 28px;

          p {
            display: list-item;
            font-size: 0.95em;
          }
        }
      }
    }

    .bvn_form {
      h5 {
        margin-bottom: 30px;
        color: $color-black-2;
        text-align: center;
        font-weight: 500;
        font-size: 1.3em;
      }

      h6 {
        margin-top: -21px;
        margin-bottom: 30px;
        color: $color-grey-3;
        text-align: center;
        font-weight: 400;
        font-size: 0.95em;
      }

      .bnv_info {
        @include flex-position(flex-start, flex-start);

        svg {
          margin-right: 10px;
        }

        p {
          font-weight: 400;
          font-size: 0.9em;
          line-height: 17px;
          color: $color-black-2;
        }
      }

      .actions {
        margin-top: 40px;
        margin-bottom: 30px;

        .btn_primary {
          padding: 15px 10px;
          font-size: 0.875em;
          width: 100%;
        }

        .or {
          margin: 15px 0px;
          font-weight: 500;
          font-size: 0.875em;
          text-align: center;
          color: $color-black;
        }

        .btn_secondary {
          padding: 15px 10px;
          font-size: 0.875em;
          width: 100%;
        }

        .didnt_get_otp {
          margin-top: 30px;
          margin-bottom: 30px;
          font-weight: 500;
          font-size: 0.875em;
          text-align: center;

          p {
            color: #161616;
          }

          .btn_text {
            margin: 0 auto;
            margin-top: 15px;
            color: $color-blue;
            cursor: pointer;
            width: fit-content;
          }
        }

        .btn_cancel {
          margin: 0 auto;
          font-weight: 500;
          font-size: 0.875em;
          color: #a2a0a8;
          width: fit-content;
          cursor: pointer;
        }
      }
    }

    .onboarding_complete {
      @include flex-position(center, center);
      flex-direction: column;
      margin-bottom: 20px;

      svg {
        margin-bottom: 30px;
        width: 90px;
        height: 90px;
      }

      .infos {
        margin-top: 20px;

        .item {
          margin-bottom: 20px;
          text-align: center;

          h6 {
            margin: 0;
            margin-bottom: 5px;
            font-weight: 500;
            font-size: 1em;
            color: $color-black-2;
          }

          p {
            margin: 0;
            font-weight: 400;
            font-size: 1.05em;
            color: $color-grey-3;
          }
        }
      }
    }
  }

  .invest_info_modal {
    @media screen and (max-height: 740px) {
      top: 370px;
    }

    .body {
      .banner {
        margin-bottom: 12px;
        height: 110px;
        width: 100%;
        object-fit: cover;
        border-radius: 5px;
      }

      .name {
        margin: 0;
        font-size: 1.1em;
        font-weight: 500;
      }

      .by {
        margin-bottom: 5px;
        font-size: 0.9em;
      }

      .summary {
        @include flex-position(flex-start, flex-start);
        flex-wrap: wrap;
        gap: 16px;
        margin-bottom: 15px;
        padding: 15px 12px;
        border: 1px solid $color-border;
        border-radius: 5px;

        .info_item {
          p {
            margin-bottom: 5px;
            font-size: 0.85em;
          }

          h5 {
            margin: 0;
            font-size: 1em;
            font-weight: 500;
          }

          h6 {
            margin: 0;
            margin-top: 3px;
            font-size: 0.9em;
            font-weight: 400;
            color: $color-grey;
          }
        }
      }

      .amount {
        margin-top: 12px;
        padding: 12px 18px;
        background: #15141f;
        border-radius: 4px;

        @media screen and (max-width: 420px) {
          padding: 12px 12px;
        }

        p {
          color: $color-white;
          font-size: 0.8em;

          &.value {
            font-weight: 500;
            font-size: 1.4em;
          }
        }
      }

      .btn_primary {
        margin-top: 25px;
        width: 100%;
      }

      .btn_text {
        margin: 0 auto;
        margin-top: 20px;
        margin-bottom: 25px;
        font-weight: 500;
        font-size: 0.9em;
        text-align: center;
        width: fit-content;
        cursor: pointer;
      }

      .loader_container {
        margin-bottom: 30px;
      }
    }
  }

  .create_target_savings_modal .body {
    .d_flex {
      @include flex-position(flex-start, center);
      gap: 24px;
      margin-bottom: 24px;

      .form_group_container {
        margin: 0;
        width: 50%;
      }
    }

    .preview_container {
      padding: 0px 24px;

      .preview {
        @include grid-columns(1fr 1fr 1fr, 20px);
        padding: 24px;
        background: #f5f5f5;
        border-radius: 4px;

        @media screen and (max-width: 470px) {
          @include grid-columns(1fr 1fr, 20px);
        }

        @media screen and (max-width: 355px) {
          @include grid-columns(1fr, 20px);
        }

        .info {
          p {
            font-size: 0.9em;
            color: #a2a0a8;
          }

          h6 {
            font-size: 1em;
            font-weight: 400;

            &.balance {
              font-weight: 500;
              color: $color-success;
            }
          }
        }
      }

      .action {
        padding: 9px 0px;
      }
    }
  }
}
