.savings_page_container {
  padding: 24px 24px;
  padding-bottom: 50px;
  min-height: 80vh;
  background: $color-white;
  border-radius: 10px;

  .btn_green {
    margin: 30px 0 0 auto;
    color: $color-white;
    background: #018335;
  }

  .listing {
    @include grid-columns(1fr 1fr 1fr, 24px);
    margin-top: 30px;

    @media screen and (max-width: 1600px) {
      @include grid-columns(1fr 1fr, 24px);
    }

    @media screen and (max-width: 1200px) {
      @include grid-columns(1fr, 24px);
    }

    .item {
      padding: 20px 15px;
      border: 1px solid $color-border;
      border-radius: 5px;

      .d_flex {
        @include flex-position(space-between, center);
        flex-wrap: wrap;
        gap: 10px;
        margin-bottom: 15px;

        .name {
          font-weight: 500;
          font-size: 1.05em;
        }

        .d_flex {
          margin: 0;
        }

        .btn {
          padding-top: 6px;
          padding-bottom: 6px;
          font-size: 0.9em;
        }
      }

      .info {
        p {
          font-size: 0.9em;
          color: #a2a0a8;
        }

        h6 {
          font-size: 1em;
          font-weight: 400;

          &.balance {
            font-weight: 500;
            color: $color-success;
          }
        }
      }

      .flex {
        @include flex-position(space-between, center);
        flex-wrap: wrap;
        gap: 13px;
        margin-top: 17px;

        .info:first-child {
          h6 {
            margin-bottom: 6px;
          }
        }

        .progress_container {
          @include flex-position(flex-start, center);

          .progress {
            height: 9px;
            width: 150px;
            max-width: 150px;
            border-radius: 20px;

            .progress-bar {
              background-color: $color-green;
            }
          }

          p {
            margin-left: 8px;
            font-size: 0.75em;
          }
        }

        .percent_away {
          font-size: 0.8125em;
          color: $color-green;
        }
      }
    }

    &.ajo_listing {
      .flex {
        margin-top: 15px;
      }

      .full_width {
        width: 100%;

        .progress {
          width: 100% !important;
          max-width: 100% !important;
        }
      }
    }
  }
}
