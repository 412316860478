.topbar_container {
  @include flex-position(space-between, center);
  margin-bottom: 35px;
  width: 100%;

  .sidebar_toggler {
    margin-right: 12px;
  }

  .titles {
    font-size: 1em;
    font-weight: 400;
    width: 100%;
    color: $color-grey-3;

    span:last-child {
      font-weight: 500;
      color: #4e4e4e;
    }
  }

  .user {
    @include flex-position(flex-start, center);
    cursor: pointer;

    .name {
      @include ellipsis;
      margin-right: 8px;
      padding: 9px 14px;
      font-size: 0.93em;
      font-weight: 500;
      color: $color-black;
      max-width: 200px;
      background: $color-white;
      border: 1px solid $color-textfield-bg;
      border-radius: 6px;

      @media screen and (max-width: 600px) {
        display: none;
      }
    }

    img {
      width: 38px;
      height: 38px;
      border: 1px solid $color-border-2;
      border-radius: 50%;
    }

    .user_avatar {
      @include flex-position(center, center);
      width: 38px;
      height: 38px;
      font-size: 1em;
      font-weight: 500;
      color: $color-white;
      background: $color-orange;
      border: 1px solid $color-border-2;
      border-radius: 50%;
    }
  }
}

.account_inactive {
  margin-top: -25px;
  margin-bottom: 35px;
  padding: 12px 12px;
  font-size: 0.9em;
  font-weight: 500;
  text-align: center;
  width: 100%;
  color: $color-error;
  background: $color-error-trans;
  border: 1px solid $color-error;
  border-radius: 3px;
}
