.home_page_container {
  width: 100%;

  .overview_actions {
    @include grid-columns(1fr 1fr 1fr 1fr 1fr 1fr, 20px);
    margin-bottom: 20px;
    width: 100%;

    @media screen and (max-width: 1690px) {
      @include grid-columns(1fr 1fr 1fr 1fr, 20px);
    }

    @media screen and (max-width: 1255px) {
      @include grid-columns(1fr 1fr 1fr, 20px);
    }

    @media screen and (max-width: 1010px) {
      @include grid-columns(1fr 1fr, 20px);
    }

    @media screen and (max-width: 500px) {
      @include grid-columns(1fr, 20px);
    }

    .account {
      grid-column-start: 1;
      grid-column-end: 3;
      @include flex-position(space-between, flex-start);
      flex-direction: column;
      padding: 26px 22px;
      background: $color-white;
      border-radius: 10px;

      @media screen and (max-width: 500px) {
        grid-column-start: 1;
        grid-column-end: 2;
      }

      @media screen and (max-width: 450px) {
        padding: 24px 16px;
      }

      .button_component {
        font-size: 0.85em;
      }

      .btn_secondary {
        svg {
          margin-top: -1px;

          path {
            fill: $color-orange;
            stroke: inherit;
          }
        }

        .loader_container img {
          width: 25px;
        }
      }

      .d_flex {
        @include flex-position(flex-start, center);
        margin-bottom: 24px;
        width: 100%;

        @media screen and (max-width: 450px) {
          flex-wrap: wrap;
          gap: 12px;
        }

        .dropdown_container {
          justify-content: flex-start;
        }

        .btn_secondary {
          width: 200px;
        }
      }

      p {
        margin-bottom: 3px;
        font-weight: 400;
        font-size: 1em;
        color: $color-grey;
      }

      h6 {
        font-weight: 500;
        font-size: 1.8em;
        color: $color-black;

        span {
          margin-left: 10px;
          cursor: pointer;
        }
      }
    }

    .action {
      padding: 26px 22px;
      background: $color-white;
      border-radius: 10px;
      cursor: pointer;

      @media screen and (max-width: 450px) {
        padding: 24px 16px;
      }

      img {
        margin-bottom: 20px;
        height: 40px;
        width: auto;
        object-fit: contain;
      }

      h6 {
        margin-bottom: 8px;
        font-size: 1.25em;
      }

      p {
        font-size: 0.875em;
        color: $color-grey-3;
      }
    }
  }

  .transactions_adbanner {
    // @include grid-columns(4fr 2fr, 20px);
    margin-bottom: 30px;
    width: 100%;

    @media screen and (max-width: 1255px) {
      display: block;
    }

    .table_container {
      box-shadow: none;

      .table_filter {
        .left {
          font-weight: 400;
          font-size: 14px;
          color: #959595;
        }
      }

      .table {
        @media screen and (max-width: 950px) {
          width: 635px;
        }

        @media screen and (max-width: 850px) {
          width: 100%;
        }

        @media screen and (max-width: 645px) {
          width: 635px;
        }
      }
    }

    .ad_banners {
      @include flex-position(flex-start, flex-start);
      flex-direction: column;
      gap: 20px;

      @media screen and (max-width: 1255px) {
        align-items: center;
        flex-direction: row;
        margin-top: 20px;
      }

      .banner {
        width: 100%;

        img {
          max-height: 300px;
          width: 100%;
          object-fit: cover;
        }
      }
    }
  }
}
