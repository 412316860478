.cards_page_container {
  @include grid-columns(3.5fr 1.5fr, 24px);
  min-height: 80vh;

  @media screen and (max-width: 1250px) {
    display: block;
  }

  &.no_grid {
    display: block;
  }

  .card_information {
    padding: 24px 24px;
    padding-bottom: 50px;
    background: $color-white;
    border-radius: 10px;

    @media screen and (max-width: 450px) {
      padding-left: 12px;
      padding-right: 12px;
    }

    .info_container {
      .tabs {
        @include flex-position(flex-start, center);
        gap: 12px;
        margin-top: 24px;

        .tab {
          padding: 3px 10px;
          font-size: 0.95em;
          border: 1px solid $color-grey-3;
          border-radius: 3px;
          cursor: pointer;

          &.active {
            color: $color-white;
            background: $color-orange;
            border: 1px solid $color-orange;
          }
        }
      }

      .content {
        margin-top: 30px;

        .table {
          @media screen and (max-width: 600px) {
            width: 527px;
          }
        }

        .settings {
          .info_detail {
            margin-bottom: 24px;

            p {
              font-size: 0.9em;
              color: $color-grey-3;
            }

            h6 {
              margin-top: 5px;
              font-size: 1.1em;
              font-weight: 400;
            }
          }

          .d_flex {
            @include flex-position(space-between, flex-start);
            flex-wrap: wrap;
            gap: 24px;
            margin-top: 30px;

            .title {
              margin-bottom: 15px;
              font-size: 1.1em;
            }

            .configs_container {
              @include flex-position(flex-start, flex-start);
              flex-wrap: wrap;
              gap: 18px;

              .config {
                padding: 15px 12px;
                width: fit-content;
                min-width: 151px;
                border: 1px solid $color-border;
                border-radius: 10px;

                &.pointer {
                  cursor: pointer;
                }

                p {
                  margin-top: 15px;
                  font-size: 0.95em;
                  color: $color-grey;
                }
              }
            }
          }
        }
      }
    }

    .request_card_container {
      .no_card {
        @include flex-position(center, center);
        flex-direction: column;
        margin: 16vh auto;

        svg {
          margin-bottom: 23px;
          width: 135px;
          height: auto;
        }

        p {
          margin-bottom: 42px;
          color: $color-grey-3;
        }

        .btn {
          width: 360px;

          @media screen and (max-width: 450px) {
            width: 100%;
          }
        }
      }

      .card_status_container {
        @include flex-position(center, center);
        flex-direction: row;
        padding: 26px 24px;
        font-weight: 500;
        max-width: 450px;
        border-radius: 4px;

        &.notify {
          background: #ffedca;
        }

        &.success {
          background: #dfffec;
        }

        svg {
          margin: 0;
          width: 80px;
          height: auto;
        }

        p {
          margin: 0;
          margin-left: 24px;
          color: $color-black;
        }
      }

      .request_card {
        @include flex-position(flex-start, flex-start);
        flex-wrap: wrap;
        gap: 80px;
        margin-top: 30px;

        @media screen and (max-width: 767px) {
          gap: 40px;
        }

        .card_info {
          width: 300px;

          h6 {
            margin-top: 20px;
            font-size: 1em;
            font-weight: 500;
            text-align: center;
          }

          .sub_title {
            margin-top: 5px;
            font-size: 0.9em;
            color: $color-grey;
            text-align: center;
          }

          .amount {
            margin: 0 auto;
            margin-top: 15px;
            padding: 2px 10px;
            font-size: 0.8em;
            font-weight: 500;
            color: $color-warning;
            text-align: center;
            width: fit-content;
            background: #ffedca;
            border-radius: 10px;
          }
        }

        .form .form_inner {
          .title {
            margin-bottom: 15px;
            font-size: 0.95em;
            font-weight: 500;
          }

          .delivery_toggle {
            @include flex-position(flex-start, center);
            margin-bottom: 15px;

            p {
              margin-right: 12px;
              font-size: 0.9em;
              font-weight: 500;
            }
          }

          .delivery_address {
            margin-bottom: 50px;

            &.preview {
              padding: 20px 18px;
              background: $color-textfield-bg;
              border-radius: 4px;

              .d_flex {
                @include grid-columns(1fr 1fr, 20px);
              }

              .info {
                margin-bottom: 16px;

                p {
                  font-size: 0.85em;
                }

                h6 {
                  margin-top: 2px;
                  font-size: 0.95em;
                  font-weight: 500;
                }
              }
            }
          }
        }

        .btn {
          width: 100%;
        }
      }
    }

    .activate_card_container {
      margin: 20vh auto;
      max-width: 450px;

      .no_card {
        margin: 0;
      }

      .item {
        @include flex-position(space-between, center);
        margin-top: 24px;
        padding: 12px 14px;
        border: 1px solid $color-textfield-bg;
        border-radius: 4px;
        cursor: pointer;

        .left {
          @include flex-position(flex-start, center);

          svg {
            margin-right: 15px;
            width: 35px;
            height: 35px;
          }

          p {
            text-transform: capitalize;
          }

          span {
            display: block;
            font-size: 0.85em;
            color: $color-grey-2;
          }
        }

        .right svg {
          width: 20px;
          transform: rotate(-90deg);
        }
      }

      .activate_card {
        h6 {
          margin-bottom: 6px;
          font-size: 1.25em;
        }

        p {
          margin-bottom: 24px;
          font-size: 0.9em;
        }

        .action .btn {
          width: 100%;
        }
      }
    }
  }

  .card {
    padding: 16px;
    background: $color-orange;
    border: none;
    border-radius: 5px;

    .balance {
      @include flex-position(space-between, center);

      img {
        width: 30px;
        height: 30px;
      }

      p {
        font-size: 0.9em;
        color: $color-white;
      }
    }

    .name {
      margin-top: 14px;
      font-size: 0.8em;
      color: $color-white;
      text-transform: uppercase;
    }

    .number {
      margin-top: 2px;
      font-size: 1em;
      font-weight: 600;
      color: $color-white;
    }

    .expiry_type {
      @include flex-position(space-between, center);
      margin-top: 10px;

      .expiry {
        .label {
          font-size: 0.5em;
          color: $color-white;
        }

        .date {
          font-size: 0.9em;
          color: $color-white;
        }
      }

      img {
        width: 64px;
        height: auto;
      }
    }
  }

  .card_details {
    padding: 24px 18px;
    padding-bottom: 50px;
    max-width: 400px;
    background: $color-white;
    border-radius: 10px;

    @media screen and (max-width: 1250px) {
      margin-top: 40px;
    }

    @media screen and (max-width: 450px) {
      padding-left: 12px;
      padding-right: 12px;
    }

    .header {
      @include flex-position(space-between, center);
      margin-bottom: 20px;

      p {
        font-size: 1.15em;
        color: $color-grey;
      }

      span {
        cursor: pointer;
      }
    }

    .infos {
      margin-top: 33px;

      .info {
        @include flex-position(space-between, center);
        margin-bottom: 24px;

        &:last-child {
          margin: 0;
        }

        p {
          font-size: 0.9em;
          color: $color-grey-3;
        }

        h6 {
          margin-top: 4px;
          font-size: 1em;
          font-weight: 500;
        }

        span {
          cursor: pointer;
        }
      }
    }
  }
}
