.form_group_div {
  margin-bottom: 9px;

  label {
    margin-bottom: 5px;
    font-weight: 400;
    font-size: 0.8125em;
    color: $color-grey;
  }

  .d_flex {
    @include flex-position(flex-start, flex-start);

    &.radio_selection_div {
      .form_group_container {
        margin: 0;
        margin-right: 20px;
        margin-bottom: 10px;

        .form-check-input {
          width: 18px;
          height: 18px;
        }

        .form-check-label {
          color: $color-black;
        }
      }
    }
  }
}

.form_group_container {
  margin-bottom: 24px;

  &:last-child {
    margin: 0;
  }

  .form-group {
    margin-bottom: 0;

    &.form-check {
      @include flex-position(flex-start, center);
      margin-bottom: 12px;
      width: fit-content;
      cursor: pointer;

      &:last-child {
        margin-bottom: 0px;
      }

      .form-check-input {
        position: relative;
        margin-top: 0;
        margin-right: 0;
        width: 18px;
        height: 18px;
        border: 1px solid $color-border;
        cursor: pointer;

        &:checked {
          background-color: $color-orange;
          border-color: $color-orange;
        }
      }

      label {
        margin: 0 !important;
        padding-left: 6px;
        font-weight: 400 !important;
        font-size: 0.95em;
        cursor: pointer;
      }
    }

    label {
      margin-bottom: 5px;
      font-weight: 400;
      font-size: 0.8125em;
      color: $color-grey;
    }

    .form-control {
      padding: 9px 12px !important;
      font-size: 1em;
      height: auto;
      border: 1px solid $color-border;
      border-radius: 10px;
      color: $color-black;

      &:focus {
        box-shadow: none !important;
        -webkit-box-shadow: none !important;
        outline: 0px auto -webkit-focus-ring-color !important;
        outline: none;
      }

      &:-webkit-autofill,
      &:-webkit-autofill:hover,
      &:-webkit-autofill:focus,
      &:-webkit-autofill:active {
        box-shadow: 0 0 0 30px white inset !important;
        -webkit-box-shadow: 0 0 0 30px white inset !important;
      }

      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
      /* Firefox */
      &[type="number"] {
        -moz-appearance: textfield;
      }

      &[type="file"] {
        padding-left: 17px !important;
      }

      &input:read-only {
        background-color: $color-border;
        opacity: 1;
      }
    }
  }

  span {
    display: flex;
    margin-top: 3px;
    font-size: 0.875em;
    line-height: normal;
    color: $color-error;
  }
}
