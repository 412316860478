.alert_container {
  position: fixed;
  bottom: 12px;
  margin-left: 12px;
  display: flex;
  height: auto;
  width: fit-content;
  width: -moz-fit-content;
  max-width: 550px;
  border-radius: 0px 3px 3px 0px;
  box-shadow: 0px 4px 7px rgba(26, 31, 76, 0.04);
  z-index: 10000;

  @media screen and (max-width: 420px) {
    right: 0px;
    margin: 0 5px;
  }

  &.account_deactivation_alert {
    position: absolute;
    top: 5px;
    bottom: auto;
    left: 0px;
    max-width: 100%;
    width: 100%;
  }

  &.info {
    color: #636464;
    border: 1px solid #f4f7ff;
    border-left: 4px solid #636464;
    background: #fefefe;

    a {
      color: #636464;
    }
  }

  &.secondary {
    color: #41464b;
    border: 1px solid #d3d6d8;
    border-left: 4px solid #41464b;
    background: #e2e3e5;

    a {
      color: #41464b;
    }
  }

  &.success {
    color: #0f5132;
    border: 1px solid #badbcc;
    border-left: 4px solid #0f5132;
    background: #d1e7dd;

    a {
      color: #0f5132;
    }
  }

  &.error {
    color: #842029;
    border: 1px solid #f5c2c7;
    border-left: 4px solid #842029;
    background: #f8d7da;

    a {
      color: #842029;
    }
  }

  &.tertiary {
    color: $color-orange;
    border: 1px solid #eee;
    border-left: 4px solid $color-orange;
    background: $color-grey;

    a {
      color: $color-orange;
    }
  }

  .alert {
    margin: 0;
    padding: 8px 12px;
    padding-bottom: 8px;

    @media screen and (max-width: 420px) {
      padding: 7px 9px;
      padding-bottom: 7px;
    }

    p {
      margin: 0;
      font-size: 1em;
      font-weight: 500;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;

      @media screen and (max-width: 420px) {
        font-size: 1em;
      }

      a {
        font-weight: bold;
        text-decoration: underline;
      }
    }

    button {
      margin-top: 4px;
      padding: 4px 8px;
      padding-bottom: 5px;
      font-size: 0.9em;
    }
  }

  .close_div {
    padding: 0px 2px;
    display: flex;
    align-items: center;
    height: inherit;
    background: rgba(0, 0, 0, 0.05);
    border-radius: 0px 3px 3px 0px;
    cursor: pointer;
  }
}

// PWA ALERT STYLING
.pwa_installer_alert {
  .alert_container {
    p {
      font-size: 0.9em !important;
    }
  }
}
