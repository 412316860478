.status_container {
  padding: 1px 14px;
  width: fit-content;
  width: -moz-fit-content;
  font-size: 0.875em;
  font-weight: 400;
  letter-spacing: 0.3px;
  border-radius: 11px;
  text-transform: capitalize;

  &.active {
    color: $color-white;
    background: rgba(48, 205, 87, 0.8);
    border: 1px dashed rgba(48, 205, 87, 0.8);
  }

  &.success {
    color: $color-success;
    background: rgba(68, 175, 105, 0.1);
    border: 1px dashed rgba(68, 175, 105, 0.2);
  }

  &.warning {
    color: $color-warning;
    background: rgba(255, 210, 76, 0.1);
    border: 1px dashed rgba(255, 210, 76, 0.2);
  }

  &.error {
    color: $color-error;
    background: rgba(219, 79, 90, 0.1);
    border: 1px dashed rgba(219, 79, 90, 0.2);
  }
}
