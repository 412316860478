.button_component {
  &.loading {
    padding: 8px 30px !important;
    padding-bottom: 9px !important;
  }

  &:disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }

  font-size: 0.95em;

  .left_icon {
    margin-right: 5px;
  }

  .right_icon {
    margin-left: 5px;
  }

  svg {
    width: 22px;
    height: 22px;

    path {
      stroke-width: 2.5px;
    }
  }
}
