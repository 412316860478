.settings_page_container {
  padding: 24px 24px;
  padding-bottom: 50px;
  min-height: 80vh;
  background: $color-white;
  border-radius: 10px;

  .change_password_container {
    @include flex-position(center, center);
    margin-top: 100px;

    .form {
      width: 360px;

      p {
        margin-bottom: 24px;
        font-size: 0.9em;
        font-weight: 500;
      }

      .action .btn {
        width: 100%;
      }
    }

    &.forgot_pin .form {
      h6 {
        margin-bottom: 14px;
        font-size: 1em;
        font-weight: 500;
        text-align: center;
      }

      p {
        margin-bottom: 60px;
        font-size: 0.9em;
        font-weight: 400;
        text-align: center;
      }
    }
  }
}
