$font-family: "Rubik", sans-serif;

$color-orange: #ff5001;
$color-black: #23242c;
$color-black-2: #15141f;
$color-grey: #727272;
$color-grey-2: #989898;
$color-grey-3: #959595;
$color-blue: #0f51fd;
$color-textfield-bg: #f5f5f5;
$color-white: #ffffff;
$color-green: #2ac66a;
$color-border: #eaeaea;
$color-border-1: rgba(144, 151, 165, 0.1);
$color-border-2: rgba(144, 151, 165, 0.2);
$color-input-border: #dddddd;
$color-bg: rgba(255, 80, 1, 0.05);
$color-success: #30cd57;
$color-success-trans: rgba(48, 205, 87, 0.15);
$color-error: #db4f5a;
$color-error-trans: rgba(219, 79, 90, 0.15);
$color-warning: #ffd24c;

$box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.06);
$box-shadow-form: 0px 1px 3px rgba(0, 0, 0, 0.1),
  0px 1px 2px rgba(0, 0, 0, 0.06);
