.statement_page_container {
  padding: 48px 48px;
  padding-bottom: 50px;
  min-height: 80vh;
  background: $color-white;
  border-radius: 10px;

  @media screen and (max-width: 700px) {
    padding: 24px 12px;
    padding-bottom: 50px;
  }

  .title {
    margin-bottom: 8px;
    font-size: 1.1em;
    font-weight: 500;
  }

  .statement_types {
    @include flex-position(flex-start, center);
    gap: 24px;
    margin-top: 40px;
    margin-bottom: 30px;

    @media screen and (max-width: 500px) {
      align-items: flex-start;
      flex-direction: column;
    }

    .item {
      @include flex-position(center, center);
      flex-direction: column;
      padding: 35px 30px;
      border: 1px solid $color-border;
      border-radius: 10px;
      cursor: pointer;

      &.active {
        border: 1px solid #0f51fd;
      }

      img {
        margin-bottom: 15px;
        width: 75px;
        height: 75px;
      }

      h6 {
        margin-bottom: 24px;
        font-size: 1em;
        font-weight: 500;
      }

      p {
        padding: 3px 12px;
        font-size: 0.9em;
        font-weight: 400;
        text-align: center;
        color: #e39e1b;
        background: #ffedca;
        border-radius: 20px;

        &.free {
          color: #018335;
          background: #dfffec;
        }
      }
    }
  }

  .form {
    max-width: 366px;

    .datepicker_container {
      width: 100%;
    }

    .action {
      margin-top: 35px;

      .btn {
        width: 100%;
      }
    }
  }
}
