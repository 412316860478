.loans_page_container {
  padding: 36px;
  padding-bottom: 50px;
  min-height: 80vh;
  background: $color-white;
  border-radius: 10px;

  @media screen and (max-width: 700px) {
    padding: 24px 12px;
    padding-bottom: 50px;
  }

  .empty_container {
    @include flex-position(center, center);
    flex-direction: column;
    margin: 16vh auto;

    svg {
      margin-bottom: 23px;
      width: 135px;
      height: auto;
    }

    p {
      margin-bottom: 42px;
      color: $color-grey-3;
    }

    .btn {
      width: 360px;

      @media screen and (max-width: 450px) {
        width: 100%;
      }
    }
  }

  .new_loan_container {
    .title {
      margin-bottom: 18px;
      font-weight: 500;
      font-size: 1.2em;
    }

    .form {
      max-width: 500px;

      .summary {
        padding: 21px 30px;
        background: #e9fff4;
        border-radius: 4px;

        @media screen and (max-width: 420px) {
          padding: 24px 12px;
        }

        .d_flex {
          @include flex-position(space-between, center);
          margin-bottom: 8px;

          p {
            font-size: 0.9em;
          }
        }

        .form-range {
          width: 100%;
        }

        .number-range {
          @include flex-position(space-between, center);
        }
      }

      .interest {
        @include flex-position(space-between, center);
        margin-top: 10px;
        padding: 16px 30px;
        background: #f5f5f5;
        border-radius: 4px;

        @media screen and (max-width: 420px) {
          padding: 16px 12px;
        }

        .percentage {
          font-weight: 500;
        }
      }

      .payment {
        margin-top: 12px;
        padding: 16px 30px;
        background: #15141f;
        border-radius: 4px;

        @media screen and (max-width: 420px) {
          padding: 16px 12px;
        }

        p {
          color: $color-white;
          font-size: 0.85em;

          &.amount {
            font-weight: 500;
            font-size: 1.6em;
          }
        }
      }

      .action {
        .btn {
          margin-top: 35px;
          padding-top: 14px;
          padding-bottom: 14px;
          width: 100%;
        }

        .btn_text {
          margin: 0 auto;
          margin-top: 20px;
          font-weight: 500;
          font-size: 0.9em;
          text-align: center;
          width: fit-content;
          cursor: pointer;
        }
      }
    }
  }

  .loans_container {
    .filter {
      @include flex-position(space-between, center);

      .btn_green {
        color: $color-white;
        width: 180px;
        background: #018335;
      }

      .dropdown_container .button_component {
        padding: 7px 20px;
      }
    }

    .listing {
      @include grid-columns(1fr 1fr 1fr, 24px);
      margin-top: 30px;

      @media screen and (max-width: 1600px) {
        @include grid-columns(1fr 1fr, 24px);
      }

      @media screen and (max-width: 1200px) {
        @include grid-columns(1fr, 24px);
      }

      .item {
        padding: 20px 24px;
        border: 1px solid $color-border;
        border-radius: 5px;

        .header {
          @include flex-position(space-between, center);
          margin-bottom: 20px;

          @media screen and (max-width: 1200px) {
            display: block;
          }

          .d_flex {
            @include flex-position(flex-start, center);
          }

          p {
            margin-right: 12px;
            font-weight: 500;
            font-size: 1.1em;
          }

          .status_container {
            font-size: 0.8em;
          }

          .btn {
            padding: 6px 15px;
            font-size: 0.8em;

            @media screen and (max-width: 1200px) {
              margin-top: 8px;
            }
          }
        }

        .infos {
          p {
            @include flex-position(space-between, center);
            font-size: 0.9em;
            width: 100%;
            color: $color-grey-3;

            span {
              color: $color-black;
            }
          }
        }
      }
    }
  }
}
