.accounts_page_container {
  padding: 36px;
  padding-bottom: 50px;
  min-height: 80vh;
  background: $color-white;
  border-radius: 10px;

  @media screen and (max-width: 700px) {
    padding: 24px 12px;
    padding-bottom: 50px;
  }

  .btn_green {
    margin: 0 0 30px auto;
    color: $color-white;
    background: #018335;
  }

  .accounts_container {
    @include grid-columns(2fr 3fr, 0px);

    @media screen and (max-width: 1480px) {
      display: block;
    }

    .left_side {
      padding-right: 40px;
      margin-right: 40px;
      border-right: 1px solid #ddd;

      @media screen and (max-width: 1480px) {
        @include grid-columns(1fr 1fr 1fr, 20px);
        padding: 0px;
        padding-bottom: 40px;
        margin: 0px;
        margin-bottom: 40px;
        border: none;
        border-bottom: 1px solid #ddd;
      }

      @media screen and (max-width: 1330px) {
        @include grid-columns(1fr 1fr, 20px);
      }

      @media screen and (max-width: 965px) {
        @include grid-columns(1fr, 15px);
      }

      .account {
        @include flex-position(center, center);
        flex-direction: column;
        position: relative;
        margin-bottom: 20px;
        padding: 36px 24px;
        background: #fffdfd;
        border: 1px solid $color-textfield-bg;
        border-radius: 5px;
        cursor: pointer;

        @media screen and (max-width: 1480px) {
          margin: 0px;
        }

        @media screen and (max-width: 1050px) {
          padding: 36px 12px;
        }

        div,
        h6 {
          color: $color-orange;
        }

        &.active {
          background: $color-orange;

          div,
          h6 {
            color: $color-white;
          }

          svg path {
            stroke: $color-white !important;
          }
        }

        &:last-child {
          margin-bottom: 0;
        }

        .hide_balance {
          position: absolute;
          top: 12px;
          right: 12px;

          svg {
            width: 1em;
            height: 1em;

            path {
              stroke: $color-orange;
            }
          }
        }

        .d_flex {
          @include flex-position(flex-start, center);
          margin-bottom: 5px;
          font-size: 0.9em;

          .copy {
            margin-left: 8px;

            svg {
              width: 0.9em;
              height: 0.9em;
            }
          }
        }

        h6 {
          margin: 0;
          font-size: 1.2em;
        }
      }
    }

    .right_side {
      .overview {
        padding: 24px;
        background: #fffdfd;
        border: 1px solid $color-textfield-bg;
        border-radius: 5px;

        @media screen and (max-width: 400px) {
          padding: 24px 12px;
        }

        .title {
          margin-bottom: 18px;
          font-size: 1.1em;
          font-weight: 500;
          text-align: center;
        }

        .d_flex {
          @include flex-position(space-between, center);

          @media screen and (max-width: 1050px) {
            display: block;
          }

          .balances {
            @include grid-columns(1fr 1fr, 10px);

            @media screen and (max-width: 530px) {
              @include grid-columns(1fr, 10px);
            }

            .info {
              padding: 16px;
              background: $color-orange;
              border-radius: 5px;

              @media screen and (max-width: 400px) {
                padding: 16px 12px;
              }

              p {
                margin-bottom: 8px;
                font-size: 0.8em;
                color: $color-white;
              }

              h6 {
                margin: 0;
                font-size: 1.05em;
                color: $color-white;
              }
            }
          }

          .actions {
            @media screen and (max-width: 1050px) {
              @include flex-position(flex-end, center);
              gap: 12px;
              margin-top: 20px;
            }

            @media screen and (max-width: 400px) {
              align-items: flex-end;
              flex-direction: column;
            }

            .btn {
              margin-bottom: 20px;

              @media screen and (max-width: 1050px) {
                margin: 0;
              }

              &:last-child {
                margin: 0;
              }
            }
          }
        }
      }

      .table_container {
        margin-top: 24px;
        box-shadow: none;

        .table_filter {
          .left {
            font-weight: 400;
            font-size: 14px;
            color: #959595;
          }
        }

        .table {
          @media screen and (max-width: 950px) {
            width: 635px;
          }

          @media screen and (max-width: 850px) {
            width: 100%;
          }

          @media screen and (max-width: 645px) {
            width: 635px;
          }
        }
      }
    }
  }
}
