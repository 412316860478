.nav-tabs {
  @include flex-position(flex-start, flex-start);
  flex-wrap: nowrap;
  padding-bottom: 0.5px;
  width: 100%;
  border-bottom: 1px solid $color-border-1;

  @media screen and (max-width: 1250px) {
    width: 935px;
  }

  .nav-item {
    width: auto;

    .nav-link {
      padding: 7px 18px;
      padding-top: 0px;
      font-size: 1em;
      color: $color-orange;
      border: none;
      border-bottom: 3px solid transparent;
      cursor: pointer;

      &:hover {
        border: none;
        border-bottom: 3px solid $color-orange;
      }

      &.active {
        font-weight: 500;
        background: transparent;
        border-bottom: 3px solid $color-orange;
      }
    }
  }
}
