.referrals_page_container {
  @include grid-columns(1fr 1fr, 24px);
  padding: 48px 48px;
  padding-bottom: 50px;
  min-height: 80vh;
  background: $color-white;
  border-radius: 10px;

  @media screen and (max-width: 1250px) {
    display: block;
  }

  @media screen and (max-width: 700px) {
    padding: 24px 12px;
    padding-bottom: 50px;
  }

  .left {
    .info {
      @include flex-position(space-between, center);
      margin-bottom: 50px;
      padding: 16px;
      border: 1px solid $color-border;
      border-radius: 10px;

      p {
        margin-bottom: 5px;
        font-size: 0.85em;
        font-weight: 400;
        color: $color-grey-3;
      }

      h6 {
        font-size: 1.4em;
        font-weight: 500;
        color: $color-orange;
      }
    }

    .records {
      .item {
        @include flex-position(space-between, center);
        margin-bottom: 16px;

        &:first-child {
          padding-bottom: 10px;
          margin-bottom: 12px;
          border-bottom: 1px solid $color-border;
        }

        &:last-child {
          margin: 0;
        }
      }
    }
  }

  .right {
    @media screen and (max-width: 1250px) {
      margin-top: 50px;
    }

    h6 {
      font-size: 1.1em;
      font-weight: 500;
      text-align: center;
    }

    .info {
      @include flex-position(center, center);
      margin-top: 20px;

      @media screen and (max-width: 500px) {
        flex-direction: column;
      }

      .link {
        margin-right: 20px;
        padding: 10px 30px;
        font-size: 0.9em;
        font-weight: 400;
        text-align: center;
        background: $color-textfield-bg;
        border-radius: 32px;
      }

      .copy {
        @include flex-position(flex-start, center);
        cursor: pointer;

        @media screen and (max-width: 500px) {
          margin-top: 15px;
        }

        svg {
          margin-right: 5px;
          width: 17px;
          height: 17px;
        }

        p {
          font-size: 1em;
          font-weight: 400;
          color: $color-orange;
        }
      }
    }
  }
}
