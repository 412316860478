.sidebar_container {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  background: $color-textfield-bg;
  border-right: 1px solid rgba(144, 151, 165, 0.1);
  z-index: 1;
  display: none;

  &.open {
    display: block;

    @media screen and (max-width: 850px) {
      &::after {
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        margin-left: 255px;
        content: "";
        background: rgba(0, 0, 0, 0.45);
      }
    }
  }

  .sidebar_container_inner {
    padding: 0px;
    padding-bottom: 30px;
    height: 100vh;
    width: 255px;
    overflow-y: scroll;
    background: rgba(255, 80, 1, 0.05);

    &::-webkit-scrollbar {
      width: 0px;
    }
    &::-webkit-scrollbar-thumb {
      width: 0px;
    }

    .padding_left_right {
      padding-right: 25px;
      padding-left: 25px;
    }

    .sidebar_toggler {
      position: absolute;
      top: 12px;
      right: 12px;
    }

    .logo_container {
      justify-content: flex-start;
      margin: 20px 0px;

      @media screen and (max-width: 850px) {
        margin-top: 45px;
      }

      img {
        width: auto;
        height: 60px;

        &.icon {
          border: none;
          border-radius: 50%;
        }
      }
    }

    .links_container {
      .link {
        @include flex-position(flex-start, center);
        padding: 9px 12px 9px 18px;
        font-size: 0.9em;
        letter-spacing: 0.02em;
        color: $color-black;
        width: 100%;
        border: 1px solid transparent;
        border-left: 4px solid transparent;
        cursor: pointer;

        &:hover {
          text-decoration: none;
          background: rgba(70, 61, 107, 0.05);
        }

        svg {
          margin-top: -1px;
          margin-right: 10px;
          width: 20px;
          height: 20px;
        }

        &.active {
          font-weight: 500;
          color: $color-orange;
          background: rgba(255, 80, 1, 0.05);
          border: 1px solid rgba(144, 151, 165, 0.05);
          border-left: 4px solid $color-orange;

          svg path {
            stroke: $color-orange;
          }
        }

        &.logout {
          position: absolute;
          bottom: 30px;
          color: $color-error;

          @media screen and (max-height: 620px) {
            position: relative;
            bottom: 0;
            margin-top: 24px;
          }
        }
      }
    }
  }
}
