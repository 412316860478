.payment_methods_container {
  h5 {
    margin-bottom: 18px;
    font-size: 1em;
    font-weight: 400;
  }

  .payment_methods {
    .payment_method {
      @include grid-columns(1fr 1fr, 20px);
      margin-bottom: 18px;
      padding: 12px 14px;
      background: $color-white;
      border: 1px solid $color-textfield-bg;
      border-radius: 5px;
      cursor: pointer;

      &:last-child {
        margin-bottom: 0;
      }

      &.active {
        border: 1px solid $color-orange;
      }

      .block {
        display: block;

        p {
          margin-bottom: 7px;
        }

        h6 {
          font-weight: 500;
        }

        img {
          height: 18px;
          width: auto;
        }
      }
    }
  }
}
