.talk_page_container {
  @include grid-columns(1fr 1fr, 24px);
  padding: 48px 48px;
  padding-bottom: 50px;
  min-height: 80vh;
  background: $color-white;
  border-radius: 10px;

  @media screen and (max-width: 700px) {
    display: block;
    padding: 24px 12px;
    padding-bottom: 50px;
  }

  .info {
    &:last-child {
      @media screen and (max-width: 700px) {
        margin-top: 50px;
      }
    }

    h6 {
      margin-bottom: 8px;
      font-size: 1.1em;
      font-weight: 500;
    }

    p {
      margin-bottom: 24px;
      font-size: 0.9em;
      font-weight: 400;
      color: $color-grey-2;
    }

    .form {
      .d_flex {
        @include flex-position(flex-start, center);
        gap: 8px;
        margin-bottom: 45px;

        .form_group_container {
          margin: 0;

          input {
            max-width: 70px;
          }
        }
      }
    }

    .socials {
      .item {
        @include flex-position(flex-start, center);
        margin-bottom: 20px;
        cursor: pointer;

        &:last-child {
          margin: 0;
        }

        img {
          margin-right: 12px;
          width: 30px;
          height: 30px;
        }

        p {
          margin: 0;
          font-size: 1em;
          font-weight: 400;
          color: #0f51fd;
        }
      }
    }
  }
}
