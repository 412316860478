.investments_page_container {
  padding: 24px 24px;
  padding-bottom: 50px;
  min-height: 80vh;
  background: $color-white;
  border-radius: 10px;

  .listing {
    @include grid-columns(1fr 1fr 1fr, 24px);
    margin-top: 30px;

    @media screen and (max-width: 1600px) {
      @include grid-columns(1fr 1fr, 24px);
    }

    @media screen and (max-width: 1200px) {
      @include grid-columns(1fr, 24px);
    }

    .item {
      padding: 15px 15px;
      border: 1px solid $color-border;
      border-radius: 5px;

      &.pointer {
        cursor: pointer;
      }

      img {
        margin-bottom: 12px;
        height: 110px;
        width: 100%;
        object-fit: cover;
        border-radius: 5px;
      }

      h6 {
        margin-bottom: 15px;
        font-weight: 1.1em;
      }

      p {
        @include flex-position(space-between, center);
        margin-bottom: 4px;
        font-size: 0.9em;
        color: $color-grey-2;

        span {
          color: $color-black;
        }

        &:last-child {
          margin: 0;
        }
      }
    }
  }
}
