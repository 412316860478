.dropdown_container {
  position: relative;
  display: flex;
  justify-content: flex-end;
  width: 100%;

  .drop {
    @include flex-position(flex-end, center);
    padding: 0;
    outline: none;
    border: none;
    background: transparent;

    .chevron {
      margin-left: 10px;

      @media screen and (max-width: 450px) {
        margin-left: 6px;
      }

      svg {
        transform: rotate(-90deg);
      }
    }
  }

  .dropdown-menu {
    top: 43px !important;
    left: auto !important;
    right: auto !important;
    padding: 0px;
    border: 1px solid $color-border-2;
    border-radius: 3px;
    box-shadow: $box-shadow;
    transform: inherit !important;

    @media screen and (max-width: 767px) {
      width: fit-content;
      width: -moz-fit-content;
    }

    .title {
      padding: 10px 12px;
      padding-bottom: 5px;
      font-size: 0.73em;
      color: rgba(132, 127, 133, 0.75);
      text-transform: uppercase;
    }

    .dropdown-item {
      padding: 9px 12px;
      font-size: 0.94em;
      background: $color-white;
      color: $color-black;
      cursor: pointer;

      &:hover,
      &:focus {
        background: $color-textfield-bg;
        outline: none;
      }

      &:first-child {
        border-radius: 4px 4px 0px 0px;
      }

      &:last-child {
        border-radius: 0px 0px 4px 4px;
      }
    }

    hr {
      margin-top: 8px;
      margin-bottom: 0;
    }
  }
}
