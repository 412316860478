.profile_page_container {
  @include flex-position(flex-start, flex-start);
  padding: 35px 0px;
  padding-bottom: 50px;
  min-height: 80vh;
  background: $color-white;
  border-radius: 10px;

  @media screen and (max-width: 530px) {
    display: block;
  }

  .nav-tabs {
    display: block;
    padding-right: 12px;
    width: fit-content;
    border: none;
    border-right: 1px solid $color-border-1;

    @media screen and (max-width: 530px) {
      @include grid-columns(repeat(2, 1fr), 12px);
      margin: 0 auto;
      padding: 0;
      border: none;
    }

    .nav-item {
      margin-bottom: 20px;

      @media screen and (max-width: 530px) {
        margin: 0;
      }

      &:last-child {
        margin: 0;
      }

      .nav-link {
        padding: 0 0 0 12px;
        color: $color-black;
        border-left: 3px solid transparent;

        @media screen and (max-width: 530px) {
          padding: 3px 12px;
          text-align: center;
          border: 1px solid transparent;
        }

        &.active,
        &:hover {
          color: $color-orange;
          border: none;
          border-left: 3px solid $color-orange;
          border-radius: 0;

          @media screen and (max-width: 530px) {
            color: $color-white;
            background: $color-orange;
            border: 1px solid $color-orange;
            border-radius: 8px;
          }
        }
      }
    }
  }

  .tab-content {
    // margin: 0 auto;
    padding: 0 48px;

    @media screen and (max-width: 600px) {
      padding: 0 12px;
    }

    @media screen and (max-width: 530px) {
      margin-top: 40px;
    }

    .datepicker_container {
      width: 100%;
    }

    .profile_section {
      .form {
        @include grid-columns(1fr 1fr, 0px);
        column-gap: 24px;
        max-width: 550px;

        @media screen and (max-width: 1160px) {
          @include grid-columns(1fr, 0px);
        }

        .action {
          padding-top: 28px;

          .btn {
            width: 100%;
          }
        }
      }
    }

    .residential_section {
      .form {
        width: 350px;

        @media screen and (max-width: 530px) {
          width: 100%;
        }

        .exists {
          margin-top: -20px;
          margin-bottom: 24px;
          font-size: 0.9em;
          color: $color-blue;
        }

        .action .btn {
          margin-top: 40px;
          width: 100%;
        }
      }
    }

    .mailing_section {
      .toggle {
        @include flex-position(flex-start, center);
        margin-bottom: 30px;

        p {
          margin-right: 10px;
        }
      }

      .preview {
        padding: 20px 18px;
        width: 350px;
        background: $color-textfield-bg;
        border-radius: 4px;

        @media screen and (max-width: 530px) {
          width: 100%;
        }

        .d_flex {
          @include grid-columns(1fr 1fr, 20px);
        }

        .info {
          margin-bottom: 16px;

          p {
            font-size: 0.85em;
          }

          h6 {
            margin-top: 2px;
            font-size: 0.95em;
            font-weight: 500;
          }
        }
      }
    }

    .next_of_kin_section .d_grid {
      @include grid-columns(1fr 1fr, 0px);
      column-gap: 40px;

      @media screen and (max-width: 850px) {
        @include grid-columns(1fr, 50px);
      }

      .toggle {
        margin-bottom: 24px;
      }

      .form,
      .preview {
        width: 100%;
        max-width: 350px;
        height: fit-content;

        @media screen and (max-width: 530px) {
          max-width: 100%;
        }
      }

      .form {
        @media screen and (max-width: 850px) {
          grid-row: 2;
        }
      }
    }

    .bvn_section {
      .bvn_form {
        @media screen and (max-width: 690px) {
          width: 100%;
        }

        h5 {
          margin-bottom: 30px;
          color: $color-black-2;
          text-align: center;
          font-weight: 500;
          font-size: 1.3em;
        }

        h6 {
          margin-top: -21px;
          margin-bottom: 30px;
          color: $color-grey-3;
          text-align: center;
          font-weight: 400;
          font-size: 0.95em;
        }

        .bnv_info {
          @include flex-position(flex-start, flex-start);

          svg {
            margin-right: 10px;
          }

          p {
            font-weight: 400;
            font-size: 0.9em;
            line-height: 17px;
            color: $color-black-2;
          }
        }

        .actions {
          margin-top: 40px;
          margin-bottom: 30px;

          .btn_primary {
            padding: 15px 10px;
            font-size: 0.875em;
            width: 100%;
          }

          .or {
            margin: 15px 0px;
            font-weight: 500;
            font-size: 0.875em;
            text-align: center;
            color: $color-black;
          }

          .btn_secondary {
            padding: 15px 10px;
            font-size: 0.875em;
            width: 100%;
          }

          .didnt_get_otp {
            margin-top: 30px;
            margin-bottom: 30px;
            font-weight: 500;
            font-size: 0.875em;
            text-align: center;

            p {
              color: #161616;
            }

            .btn_text {
              margin: 0 auto;
              margin-top: 15px;
              color: $color-blue;
              cursor: pointer;
              width: fit-content;
            }
          }

          .btn_cancel {
            margin: 0 auto;
            font-weight: 500;
            font-size: 0.875em;
            color: #a2a0a8;
            width: fit-content;
            cursor: pointer;
          }
        }
      }
    }
  }
}
