.datepicker_container {
  @include flex-position(flex-start, center);
  padding: 10px 10px 9px 12px;
  width: fit-content;
  height: auto;
  border: 1px solid $color-border;
  border-radius: 10px;
  background: $color-white;

  &:focus-within {
    border: 1px solid $color-orange;
  }

  svg {
    margin-top: -2px;
    margin-right: 8px;
    width: 20px;
    height: 20px;
  }

  .react-datepicker-wrapper {
    margin-top: -2px;
    width: 201px;

    .date_picker_input {
      font-size: 0.95em;
      color: $color-black;
      height: auto;
      width: 100%;
      background: transparent;
      border: none;

      &:focus {
        border: none !important;
      }
    }
  }
}
